import React, { useState } from 'react'
import ProjectsFilter from '../components/ProjectsFilter';
import ProjectsList from '../components/ProjectsList';
import { useMediaQuery } from 'react-responsive'

export default function Projects(props) {
  const [bgURL, setBgURL] = useState('');
  const [filter, setFilter] = useState('all');
  const isMediumScreen = useMediaQuery({ query: '(max-width: 900px)' });

  return (
    <div className='projects' style={!isMediumScreen ? {backgroundImage: `url(${bgURL})`} : null}>
      <div className="projects__wrapper">
      <ProjectsFilter filterOptions={props.filterOptions} filter={filter} setFilter={setFilter} />
      <ProjectsList filter={filter} bgURL={bgURL} setBgURL={setBgURL}/>
      </div>
    </div>
  )
}
