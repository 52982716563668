import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import { ProjectContext } from '../ProjectContext'

export default function ProjectsList(props) {
    var projectList = null;
    const projects = useContext(ProjectContext);
    var displayProjectList = () => {
        projectList = projects.map((project,index) => {
            if(props.filter === project.acf.category || props.filter === "all"){
                return (<li 
                    key={index}
                    className='projects__list-item'
                        onMouseEnter={() => props.setBgURL(project.acf.featured_media)}
                        onMouseLeave={() => props.setBgURL('')}
                    ><Link to={`/projects/${project.acf.artist.replace(/\s+/g, '-').toLowerCase()}/${project.acf.project_number}`}>
                        {project.acf.project_number} | {project.acf.artist} | {project.acf.client} | {project.acf.tour}
                    </Link></li>);
            }
            return null;            
        });
    }
    displayProjectList();
  return (
        
    <ul className="projects__list">
        {projectList}        
    </ul>
  )
}
