import React from 'react'
export default function Arrows(props) {
    const { className, style, onClick } = props;
    
  return (

    <div className={className} style={{...style, display: "none"}} onClick={onClick}
    ></div>
  )
}
