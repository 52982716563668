import React from 'react'
export default function ProjectsFilter(props) {

  var handleClick = (filterVal) => {
      props.setFilter(filterVal);
  }

  return (
    <ul className='projects__filter'>
      {
        props.filterOptions.length !== 0 ?
        <li className='projects__filter-item' onClick={() => handleClick('all')}>ALL</li> : 
        null
      }
      {props.filterOptions.map(filterOption => {
        return <li className='projects__filter-item' onClick={() => handleClick(filterOption)}>{filterOption.replace(/_/g, ' ').toUpperCase()}</li>
      })}
    </ul>
  )
}
