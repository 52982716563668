/* eslint-disable jsx-a11y/anchor-is-valid */
import {
	BrowserRouter as Router,
	Routes,
	Route,
	NavLink,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles/style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import ProjectSingle from "./pages/ProjectSingle";
import { ProjectContext } from "./ProjectContext";
function App() {
	const [projects, setProjects] = useState([]);
	const [contacts, setContacts] = useState([]);
	const [filterOptions, setFilterOptions] = useState([]);

	useEffect(() => {
		axios
			.get(
				"https://visionome.wpengine.com/wp-json/wp/v2/projects/?per_page=100"
			)
			.then((res) => {
				setProjects(res.data);
			});
		axios
			.get(
				"https://visionome.wpengine.com/wp-json/wp/v2/contacts"
			)
			.then((res) => {
				setContacts(res.data);
			});
		axios
		.options(
			"https://visionome.wpengine.com/wp-json/wp/v2/projects/"
		)
		.then((res) => {
			setFilterOptions(res.data.endpoints[1].args.acf.properties.category.items.enum);
		});
	}, []);

	function setActiveNav(navigationData, isHome) {
		let pathArr = window.location.pathname.split("/");
		if (pathArr[1] === "projects" && pathArr.length === 4) {
			if (isHome === true) {
				return "site-nav__active site-nav__list-item";
			}
			return "site-nav__list-item";
		}
		if (navigationData.isActive) {
			return "site-nav__active site-nav__list-item";
		} else return "site-nav__list-item";
	}
	const appHeight = () => {
		const doc = document.documentElement;
		doc.style.setProperty("--app-height", `${window.innerHeight}px`);
	};
	window.addEventListener("resize", appHeight);
	appHeight();
	return (
		<div className='App'>
			<Router>
				<ProjectContext.Provider value={projects}>
					<Routes>
						<Route
							path='/projects/:projectArtist/:projectNumber'
							element={<ProjectSingle />}
						/>
						<Route path='/projects' element={<Projects filterOptions={filterOptions} />} />
						<Route path='/' element={<Home />} />
					</Routes>
				</ProjectContext.Provider>
				<nav className='site-nav'>
					<ul className='site-nav__list'>
						<NavLink
							to='/'
							className={(navigationData) => setActiveNav(navigationData, true)}
						>
							HOME
						</NavLink>
						<NavLink
							to='/projects'
							className={(navigationData) => setActiveNav(navigationData)}
						>
							PROJECTS
						</NavLink>
					</ul>
					<ul className='site-nav__communication'>
						<li className='site-nav__communication-item'>
							<a
								href={
									contacts.length > 0 ? `mailto:${contacts[0].acf.email}` : "#"
								}
							>
								CONTACT
							</a>
						</li>
						<li className='site-nav__communication-item'>
							<a
								href={
									contacts.length > 0 ? contacts[0].acf.instagram : "#"
								}
							>
								INSTAGRAM
							</a>
						</li>
					</ul>
				</nav>
			</Router>
		</div>
	);
}

export default App;
