import React,{useState,useEffect} from 'react'
import { Link } from "react-router-dom";

export default function HomeSliderItem(props) {
  const [sliderImg, setSliderImg] = useState(null);
  const [sliderVideo, setSliderVideo] = useState(null);
  function handleArrowClick(e){
    var arrowEle = null;
    if(e.target.classList.contains('slick-prev')){
      arrowEle = document.querySelectorAll('.slick-prev');
      arrowEle[0].click();
    }
    else if(e.target.classList.contains('slick-next')){
      arrowEle = document.querySelectorAll('.slick-next');
      arrowEle[arrowEle.length - 1].click();
    }
  }
  useEffect(() => {
    if(props.sliderImg !== null) setSliderImg(props.sliderImg);
    if(props.sliderVideo !== null) setSliderVideo(props.sliderVideo);
  }, [props.sliderImg,props.sliderVideo,sliderImg]);
  
  return (
    <div className='home__slider-item'>
      <Link to={`/projects/${props.projectArtist.replace(/\s+/g, '-').toLowerCase()}/${props.projectNumber}`} className="home__slider-link">
        {sliderVideo !==null ?
          <div className='home__slider-video'>
            <video key={sliderVideo} autoPlay={true} muted={true} loop={true} playsInline={true}>
              <source src={sliderVideo} type="video/mp4" />
            </video>
          </div>
        :
          (sliderImg != null ? 
            <div className='home__slider-img' style={{"backgroundImage":`url(${sliderImg})`}}></div>: <div className="home__slider-blank"></div>)
        }
        
      </Link> 
      <div 
      className="
        home__slider-overlay
        "
      style={{background:props.overlayColor}}
      >
      <div 
      className='
        slick-arrow 
        slick-prev 
        display-left-arrow
      ' 
        onClick={handleArrowClick}></div>
          <div className="home__slider-overlay-content">
            <Link to={`/projects/${props.projectArtist.replace(/\s+/g, '-').toLowerCase()}/${props.projectNumber}`}>
              <h3 className="home__slider-client">
                {props.client} {props.projectNumber}
                </h3>
              <h2 className="home__slider-tour">{props.tour}</h2>
              <h3 className="home__slider-artist">{props.artist}</h3>
            </Link>
          </div>
        <div 
        className='
          slick-arrow 
          slick-next display-right-arrow
        ' 
          onClick={handleArrowClick}></div>

      </div>
    </div>
  )
}
