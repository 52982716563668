import React, { useContext, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ProjectContext } from '../ProjectContext'
import { useParams } from 'react-router-dom'
import Parser from 'html-react-parser';

export default function ProjectSingle() {
  const [hoverOn, setHoverOn] = useState(false);
  const isMediumScreen = useMediaQuery({ query: '(max-width: 900px)' })
  const projects = useContext(ProjectContext);

  var projectDisplay = null;
  var headerHoverStyle = {};
  let params = useParams();

  if (hoverOn === true) {
    headerHoverStyle = {
      "backgroundColor": "rgba(0, 0, 0, 0.6)",
      "height": "100vh"
    }
  }
  else {
    headerHoverStyle = {};
  }

  function handleDesktopHover() {
    if (!isMediumScreen) setHoverOn(!hoverOn)
    else return
  }
  function handleMobileClick() {
    if (isMediumScreen) setHoverOn(!hoverOn)
    else return
  }

  var displayProject = () => {
    projectDisplay = projects.map((project) => {
      var creditsText = (
        <div className="project-single__credits-text">
          {Parser(project.acf.credits_text)}
        </div>
      );
      if (project.acf.project_number === params.projectNumber && project.acf.artist.toUpperCase() === params.projectArtist.replace(/-/g, ' ').toUpperCase()) {
        return (
          <>
            <div className="project-single__header" style={headerHoverStyle}>
              <div className='project-single__header-text-1'>
                <h3 className="">
                  {project.acf.project_number} {project.acf.client}
                </h3>
                <button onClick={() => { setHoverOn(!hoverOn); }} className='project-single__credits-text-close' style={hoverOn && isMediumScreen ? { "display": "block" } : null}>
                </button>
              </div>

              <h1 className="project-single__header-text-2">
                {project.acf.tour}
              </h1>
              <h2 className="project-single__header-text-3">
                {project.acf.artist}
              </h2>
              <div
                className="project-single__credits-button"
                onMouseEnter={handleDesktopHover}
                onMouseLeave={handleDesktopHover}
                onClick={handleMobileClick}
              >
                CREDITS
              </div>
              {hoverOn === true ? creditsText : null}

            </div>
            <div className="project-single__gallery">
              {project.acf.images.map((image) => {
                return (<img className='project-single__gallery-image' alt={image.caption} src={image.full_image_url} />);
              })}
              {project.acf.video_0 === false ?
                null :
                <video muted={true} autoPlay={true} playsInline={true} className='project-single__gallery-image' alt="portfolio video">
                  <source src={project.acf.video_0} type="video/mp4" />
                </video>
              }
              {project.acf.video_1 === false ?
                null :
                <video muted={true} autoPlay={true} playsInline={true} className='project-single__gallery-image' alt="portfolio video">
                  <source src={project.acf.video_1} type="video/mp4" />
                </video>
              }
            </div>
          </>
        )
      }
      return null;

    });
  }
  displayProject();

  return (
    <div className='project-single'>
      {projectDisplay}
    </div>
  )
}
