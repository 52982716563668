import React, { useContext } from 'react'
import Slider from "react-slick";
import HomeSliderItem from './HomeSliderItem';
import Arrows from './Arrows';
import { ProjectContext } from '../ProjectContext'
import { useMediaQuery } from 'react-responsive'

export default function HomeSlider() {
  const projects = useContext(ProjectContext);
  const isMediumScreen = useMediaQuery({ query: '(max-width: 900px)' });
  
  function getSliderImgBG(project){
    if (projects.length <= 0){
      return null
    } else if(!isMediumScreen) {
      if (project.acf.hasOwnProperty('slider_image') && project.acf.slider_image !== false) {
        return project.acf.slider_image;
      } else return null;
    } else {
      if (project.acf.hasOwnProperty('slider_image_mobile') && project.acf.slider_image_mobile !== false) {
        return project.acf.slider_image_mobile;
      } else return null;
    }
  }
  function getSliderVideoBG(project){
    if (projects.length <= 0){
      return null
    } else if(!isMediumScreen) {
      if (project.acf.hasOwnProperty('slider_video') && project.acf.slider_video !== false) {
        return project.acf.slider_video;
      } else return null;
    } else {
      if (project.acf.hasOwnProperty('slider_video_mobile') && project.acf.slider_video_mobile !== false) {
        return project.acf.slider_video_mobile;
      } else return null;
    }
  }
    var settings = {
        arrows: true,
        infinite: true,
        autoplaySpeed:3000,
        prevArrow: <Arrows />,
        nextArrow: <Arrows />,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    var sliderItems = null;
    var displaySliderItems = () => {
      sliderItems = projects.map((project,index) => {
          if(project.acf.enable_on_home_slider === true){
            return (
              <div>
                <HomeSliderItem
                key={index} 
                tour={project.acf.tour} 
                client={project.acf.client} 
                projectNumber={project.acf.project_number} 
                projectArtist={project.acf.artist} 
                artist={project.acf.artist}
                sliderImg={() => getSliderImgBG(project)}
                sliderVideo={() => getSliderVideoBG(project)}
                overlayColor={project.acf.overlay_color}
                />
              </div>
            );
          }
          return null
      });
    }
    displaySliderItems();
  return (
    <div className='home__slider'>
      <Slider {...settings}>
        {sliderItems}
      </Slider>
    </div>
  )
}
